//BLusplenger
import BL1 from "./../../media/Blitzschutze/BL1.jpeg";
import BL2 from "./../../media/Blitzschutze/BL2.jpeg";
import BL3 from "./../../media/Blitzschutze/BL3.jpeg";
import BL4 from "./../../media/Blitzschutze/BL4.jpeg";
import BL5 from "./../../media/Blitzschutze/BL5.jpeg";
import BL6 from "./../../media/Blitzschutze/BL6.jpeg";
import BL7 from "./../../media/Blitzschutze/BL7.jpeg";
import BL8 from "./../../media/Blitzschutze/BL8.jpeg";
import BL9 from "./../../media/Blitzschutze/BL9.jpeg";
import BL10 from "./../../media/Blitzschutze/BL10.jpeg";
import BL11 from "./../../media/Blitzschutze/BL11.jpeg";
import BL12 from "./../../media/Blitzschutze/BL12.jpeg";
import BL13 from "./../../media/Blitzschutze/BL13.jpeg";
import BL14 from "./../../media/Blitzschutze/BL14.jpeg";
import BL15 from "./../../media/Blitzschutze/BL15.jpeg";
import BL16 from "./../../media/Blitzschutze/BL16.jpeg";
import BL17 from "./../../media/Blitzschutze/BL17.jpeg";



const DataBL = [
  {
    category: "Blitzschutz",
    image: BL1,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL2,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL3,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL4,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL5,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL6,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL7,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL8,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL13,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL14,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL15,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL16,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL17,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL9,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL10,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL11,
    title: "Blitzschutz",
  },
  {
    category: "Blitzschutz",
    image: BL12,
    title: "Blitzschutz",
  },

];
export default DataBL;
