import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import BrowserRouter
import Home from "./components/home/Home";
import Baus from "./components/Bauspenglerei/Bauspenglerei";
import Flachdach from "./components/Flachdach/Flachdach";
import Blitzschutze from "./components/Blitzschutze/Blitzschutze"
function App() {
  return (
    <BrowserRouter>
      {/* Wrap your Routes with BrowserRouter */}
      <div className=" h-full w-full overflow-x-hidden ">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bauspengler" element={<Baus />} />
          <Route path="/flachdach" element={<Flachdach />} />
          <Route path="/blitzschutz" element={<Blitzschutze />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
