import BA1 from "./../../media/Bauspenglerei/BA1.jpeg"
import BA2 from "./../../media/Bauspenglerei/BA2.jpeg"
import BA3 from "./../../media/Bauspenglerei/BA3.jpeg"
import BA4 from "./../../media/Bauspenglerei/BA4.jpeg"
import BA5 from "./../../media/Bauspenglerei/BA5.jpeg"
import BA7 from "./../../media/Bauspenglerei/BA7.jpeg"
import BA8 from "./../../media/Bauspenglerei/BA8.jpeg"
import BA9 from "./../../media/Bauspenglerei/BA9.jpeg"
import BA10 from "./../../media/Bauspenglerei/BA10.jpeg"
import BA11 from "./../../media/Bauspenglerei/BA11.jpeg"
import BA12 from "./../../media/Bauspenglerei/BA12.jpeg"
import BA14 from "./../../media/Bauspenglerei/BA14.jpeg"
import BA15 from "./../../media/Bauspenglerei/BA15.jpeg"
import BA16 from "./../../media/Bauspenglerei/BA16.jpeg"
import BA17 from "./../../media/Bauspenglerei/BA17.jpeg"
import BA18 from "./../../media/Bauspenglerei/BA18.jpeg"
import BA19 from "./../../media/Bauspenglerei/BA19.jpeg"
import BA20 from "./../../media/Bauspenglerei/BA20.jpeg"
import BA21 from "./../../media/Bauspenglerei/BA21.jpeg"
import BA22 from "./../../media/Bauspenglerei/BA22.jpeg"
import BA23 from "./../../media/Bauspenglerei/BA23.jpeg"
import BA24 from "./../../media/Bauspenglerei/BA24.jpeg"
import BA25 from "./../../media/Bauspenglerei/BA25.jpeg"
import BA26 from "./../../media/Bauspenglerei/BA26.jpeg"
import BA27 from "./../../media/Bauspenglerei/BA27.jpeg"
import BA28 from "./../../media/Bauspenglerei/BA28.jpeg"

const DataB =[
  // {
  //     category: "bauspenglerei",
  //     image: BA1,
  //     title: "Card 1",
  //     // additional_images: [
  //     //   B2,
  //     //   B3,
  //     //   B4,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B10,
  //     //   B2,
  //     //   B3
  //     // ]
    
  // },
  {
    category: "bauspenglerei",
    image: BA1,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA2,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA3,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA4,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA5,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA7,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA8,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA9,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA10,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA11,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA12,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA14,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA15,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA16,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA17,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA18,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA19,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA20,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA21,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA22,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA23,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA24,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA25,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA26,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA27,
    title: "Bauspenglerei",
  },
  {
    category: "bauspenglerei",
    image: BA28,
    title: "Bauspenglerei",
  },


]

export default DataB;