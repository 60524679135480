import React from "react";
import Navbar from "../Navbar";
import Hero from "./Hero";
import Services from "./Services";
import About from "./About";
import Contact from "./Contact";
import Footer from "../Footer";
function Home() {
  return (
    <div className=" ">
        <Navbar/>
        <Hero/>
        <Services/>
        <About/>
        <Contact/>
        <Footer/>
    </div>
  );
}
export default Home;
