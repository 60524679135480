//Bausplenger
import BA1 from "./../media/Bauspenglerei/BA1.jpeg";
import BA2 from "./../media/Bauspenglerei/BA2.jpeg";
import BA3 from "./../media/Bauspenglerei/BA3.jpeg";
import BA4 from "./../media/Bauspenglerei/BA4.jpeg";
import BA5 from "./../media/Bauspenglerei/BA5.jpeg";
import BA7 from "./../media/Bauspenglerei/BA7.jpeg";
import BA8 from "./../media/Bauspenglerei/BA8.jpeg";
import BA9 from "./../media/Bauspenglerei/BA9.jpeg";
import BA10 from "./../media/Bauspenglerei/BA10.jpeg";
import BA11 from "./../media/Bauspenglerei/BA11.jpeg";
import BA12 from "./../media/Bauspenglerei/BA12.jpeg";
import BA13 from "./../media/Bauspenglerei/BA12.jpeg";

//Flachdach
import FL1 from "./../media/Flachdach/FL1.jpeg";
import FL2 from "./../media/Flachdach/FL2.jpeg";
import FL3 from "./../media/Flachdach/FL3.jpeg";
import FL4 from "./../media/Flachdach/FL4.jpeg";
import FL5 from "./../media/Flachdach/FL5.jpeg";
import FL7 from "./../media/Flachdach/FL7.jpeg";
import FL8 from "./../media/Flachdach/FL8.jpeg";
import FL9 from "./../media/Flachdach/FL9.jpeg";
import FL10 from "./../media/Flachdach/FL10.jpeg";
import FL11 from "./../media/Flachdach/FL11.jpeg";
import FL12 from "./../media/Flachdach/FL12.jpeg";
import FL13 from "./../media/Flachdach/FL12.jpeg";

//Blitschutze
import BL1 from "./../media/Blitzschutze/BL1.jpeg";
import BL2 from "./../media/Blitzschutze/BL2.jpeg";
import BL3 from "./../media/Blitzschutze/BL3.jpeg";
import BL4 from "./../media/Blitzschutze/BL4.jpeg";
import BL5 from "./../media/Blitzschutze/BL5.jpeg";
import BL6 from "./../media/Blitzschutze/BL6.jpeg";
import BL13 from "./../media/Blitzschutze/BL7.jpeg";
import BL14 from "./../media/Blitzschutze/BL8.jpeg";
import BL15 from "./../media/Blitzschutze/BL9.jpeg";
import BL16 from "./../media/Blitzschutze/BL10.jpeg";
import BL17 from "./../media/Blitzschutze/BL11.jpeg";

const DataS = [
  {
    category: "bauspenglerei",
    image: BA1,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL1,
    title: "Flachdach",
  },
  {
    category: "blitzschutze",
    image: BL1,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA2,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL2,
    title: "Flachdach",
  },
  {
    category: "blitzschutze",
    image: BL13,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA3,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL3,
    title: "Flachdach",
  },
  {
    category: "blitzschutze",
    image: BL14,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA4,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL4,
    title: "Flachdach",
  },
  {
    category: "blitzschutze",
    image: BL15,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA5,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL5,
    title: "Flachdach",
  },
  {
    category: "blitzschutze",
    image: BL16,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA7,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL7,
    title: "Flachdach",
  },
  {
    category: "blitzschutze",
    image: BL6,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA8,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL13,
    title: "Flachdach",
  },
  {
    category: "bauspenglerei",
    image: BA13,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL8,
    title: "Flachdach",
  },
  {
    category: "Blitzschutze",
    image: BL17,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA9,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL9,
    title: "Flachdach",
  },
  {
    category: "Blitzschutze",
    image: BL2,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA10,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL10,
    title: "Flachdach",
  },
  {
    category: "Blitzschutze",
    image: BL3,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA11,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL11,
    title: "Flachdach",
  },
  {
    category: "Blitzschutze",
    image: BL4,
    title: "Blitzschutz",
  },
  {
    category: "bauspenglerei",
    image: BA12,
    title: "Bauspenglerei",
  },
  {
    category: "Flachdach",
    image: FL12,
    title: "Flachdach",
  },
  {
    category: "Blitzschutze",
    image: BL5,
    title: "Blitzschutz",
  },
];
export default DataS;
