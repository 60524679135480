import React, { useState, useEffect, useRef } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import logo from "./../media/logo.png";
function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getCurrentPath = () => {
    const path = window.location.pathname;
    return path.endsWith("/") ? path.slice(0, -1) : path;
  };
  const currentPath = getCurrentPath();

  const navbarRef = useRef(null);

  // Close the menu when clicked outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isMenuOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  // Make the mobile version navbar sticky
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        if (window.scrollY > 0) {
          navbarRef.current.classList.add("sticky");
        } else {
          navbarRef.current.classList.remove("sticky");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="sm:ml-4 md:ml-0 sm:mr-0 md:mr-0 ">
      <nav
        ref={navbarRef}
        className={`bg-background md:bg-transparent p-2 md:p-0 ${
          isMenuOpen ? "open-menu" : ""
        }`}
      >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto sm:p-4 md:p-0  ">
          <a href="/" className="md:flex items-center hover:scale-105">
            <img
              src={logo}
              className="h-20 md:h-32 ml-0 "
              alt=" Swiss Spengler Logo"
            />
          </a>
          <div className="flex  justify-between  md:order-2 ">
            <a
              href="tel:+41767300007"
              className="hover:scale-105 text-palette "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-8 md:w-7 h-8 md:h-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                />
              </svg>
            </a>
            <a
              href="mailto:Info@swissspengler.ch"
              className="hover:scale-105 text-palette mx-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-8 md:w-7 h-8 md:h-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </a>

            <a
              href="https://www.instagram.com/swissspengler"
              target="_blank"
              className="hover:scale-105 text-palette"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="w-8 md:w-7 h-8 md:h-7"
              >
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
              </svg>
            </a>
          </div>
          <div className="md:hidden sm:block -mt-3 w-9 h-8 mr-4">
            <Hamburger
              label="Show menu"
              easing="ease-in"
              toggled={isMenuOpen}
              toggle={setIsMenuOpen}
              distance="m"
              color="#e7b847"
              duration={0.4}
            ></Hamburger>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
              isMenuOpen ? "block " : "hidden"
            }`}
          >
            <ul className="flex flex-col font-medium  p-4 md:p-0 mt-4 md mb-2 md:mb-0 border border-palette  md:flex-row md:space-x-8 md:mt-0 md:border-0">
              <li>
                <a
                  href="/"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === ""
                      ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette "
                      : ""
                  }`}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/bauspengler"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === "/bauspengler"
                      ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette "
                      : ""
                  }`}
                >
                  Bauspengler
                </a>
              </li>
              <li>
                <a
                  href="/flachdach"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === "/flachdach"
                      ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette "
                      : ""
                  }`}
                >
                  Flachdach
                </a>
              </li>
              <li>
                <a
                  href="/blitzschutz"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === "/blitzschutz"
                    ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette"
                    : ""
                  }`}
                >
                  Blitzschutz
                </a>
              </li>
              {/* <li>
                <a
                  href="#uns"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette  ${
                    currentPath === "/uns"
                    ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette "
                    : ""
                  }`}
                >
                  {" "}
                  Über uns{" "}
                </a>
              </li> */}
              {/* <li>
                <a
                  href="/#kontakt"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette  ${
                    currentPath === "/kontakt"
                    ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette "
                      : ""
                  }`}
                >
                  {" "}
                  Kontakt
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
