import React from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";

import "./About.css";
function Contact() {
  const libraries = ["places"];
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };
  const center = {
    lat: 47.45489289062575, // default latitude
    lng: 8.5310861000412, // default longitude
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAwpOK_o31CtNCw1DZB3bMwIbKYRT-_oTw",
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div id="kontakt" className=" mt-10 ">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-3xl text-palette text-center "
      >
        Kontakt
      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 md:ml-24 mr-8 md:mr-24 mb-5 md:mb-10 "></div>
      </div>
      <div className=" md:grid grid-cols-2 ml-8 md:ml-24 mr-8 md:mr-4">
        <div
          data-aos="fade-right"
          data-aos-duration="1200"
          className=" col-span-1 hidden md:block  h-128 mb-20"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5395.534918809581!2d8.5264903!3d47.4554722!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479075302348211f%3A0xa2241736ea3cebbd!2sHellbuckstrasse%2015%2C%208153%20R%C3%BCmlang%2C%20Switzerland!5e0!3m2!1sen!2smk!4v1700169899244!5m2!1sen!2smk"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className=" md:grid grid-cols-2 md:ml-4 ">
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette md:text-xl font-bold">
              {" "}
              Telefonnummer
            </h1>
            <div className="text-palette mt-2 md:mt-4 mb-2 md:mb-0 border-b-2 md:mr-16 border-palette font-semibold ">
              <a href="tel:+410767300007" className="hover:text-paletteof">
                076 730 00 07
              </a>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette md:text-xl font-bold">
              {" "}
              Allgemeine E-Mail
            </h1>
            <p className="text-palette mt-4 mb-2 md:mb-0 border-b md:mr-20 border-palette ">
              Info@swissspengler.ch{" "}
            </p>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette md:text-xl font-bold"> Adress</h1>
            <div className="text-palette font-semibold  mt-2 md:mt-4 mb-2 md:mb-0 border-b border-palette md:mr-16 ">
              <a
                href="https://www.google.com/maps/place/Hellbuckstrasse+15,+8153+R%C3%BCmlang,+Switzerland/@47.4554722,8.5264903,16z/data=!4m6!3m5!1s0x479075302348211f:0xa2241736ea3cebbd!8m2!3d47.4547478!4d8.5310861!16s%2Fg%2F11c1wq8nmn?entry=ttu"
                target="_blank"
                className="hover:text-paletteof"
              >
                Hellbuckstrasse 15, 8153 Rümlang{" "}
              </a>
            </div>
          </div>
          <a
            data-aos="fade-left"
            data-aos-duration="1200"
            href="mailto:Info@swissspengler.ch"
            className="hidden text-center text-palette text-lg   hover:text-white border border-palette hover:bg-palette mr-20  mb-28 md:flex justify-center items-center"
          >
            Vereinbaren Sie einen Termin
          </a>
        </div>
        <a
          data-aos="fade-up"
          data-aos-duration="1000"
          href="mailto:Info@swissspengler.ch"
          className="block md:hidden text-center text-white text-base bg-background hover:bg-default hover:text-white border border-palette   py-2 px-4 mt-5   mr-8 ml-8 mb-8 "
        >
          Vereinbaren Sie einen Termin
        </a>
        <div
          data-aos="fade-up"
          data-aos-duration="1200"
          className=" col-span-1 block md:hidden  h-96 mb-20"
        >
           <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5395.534918809581!2d8.5264903!3d47.4554722!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479075302348211f%3A0xa2241736ea3cebbd!2sHellbuckstrasse%2015%2C%208153%20R%C3%BCmlang%2C%20Switzerland!5e0!3m2!1sen!2smk!4v1700169899244!5m2!1sen!2smk"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
export default Contact;
