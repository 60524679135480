import { useState, useEffect } from "react";
import Modal from "react-modal";
import "./Projects.css";
import DataF from "../../json/Flachdach/DataF";
import AOS from "aos";
import "aos/dist/aos.css";
function Projects() {
  useEffect(() => {
    AOS.init();
  }, []);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const images = DataF;

  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 6;

  const openModal = (image, index) => {
    setSelectedImage(image);
    setModalImageIndex(index);
    setModalIsOpen(true);
  };
  const openImage = (image) => {
    setSelectedImage(image);
  };
  const handleCloseImage = () => {
    setSelectedImage(null);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
    setModalImageIndex(0);
  };

  const handleOpenImage = (index) => {
    setIsOpen(true);
    setCurrentIndex(index);
  };

  // const handleCloseImage = () => {
  //   setIsOpen(false);
  //   setCurrentIndex(null);
  // };

  // const handleNextImage = () => {
  //   setCurrentIndex((currentIndex + 1) % images.length);
  // };

  // const handlePrevImage = () => {
  //   setCurrentIndex((currentIndex + images.length - 1) % images.length);
  // };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const displayedCards = DataF.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(DataF.length / cardsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      const projekteElement = document.getElementById("projekte");
      if (projekteElement) {
        projekteElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const projekteElement = document.getElementById("projekte");
      if (projekteElement) {
        projekteElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div id="projekte" className="md:mt-10">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-3xl text-palette text-center"
      >
        Projekte
      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 md:ml-24 mr-8 md:mr-24 mb-10 "></div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="px-5 md:mb-10 cursor-pointer"
      >
        {" "}
        <div className="flex justify-center">
          <div className="md:grid grid-cols-3 md:gap-4">
            {displayedCards.map((card, index) => (
              <div
                className="p-4 hover:scale-105"
                key={index}
                onClick={() => openImage(card.image)}
              >
                <div className="relative card">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center"
                    src={card.image}
                  ></img>
                  <div className="px-8 py-4 absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white">
                    <h1 className="text-center text-lg font-bold text-white mb-3">
                      {card.title}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#e7b847"
                        className="w-9 h-9 absolute bottom-2 right-2 hover:scale-110"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {selectedImage && (
          <Modal
            isOpen={true} // Set this to true to open the modal
            onRequestClose={handleCloseImage}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 10000,
              },
              content: {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                borderColor: "transparent",
              },
            }}
          >
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="relative w-fit border border-black">
                <button
                  className="absolute right-5 md:-right-10 -top-14 md:-top-10 text-white bg-palette px-2 hover:scale-105 text-2xl font-bold"
                  onClick={handleCloseImage}
                >
                  X
                </button>
                <img
                  src={selectedImage}
                  alt="rooms"
                  className="object-cover object-center h-96 md:h-152"
                />
              </div>
            </div>
          </Modal>
        )}
        <div className="flex justify-center text-palette mt-5 md:mt-5 mb-5 md:mb-8">
          {currentPage > 1 && (
            <button
              className="text-4xl mr-2 -mt-1 md:mt-0 hover:scale-125"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              &lt; {/* Left arrow */}
            </button>
          )}
          {Array.from({ length: totalPages }, (_, i) => (
            <div
              key={i}
              className={`bg-palette rounded-full py-2 px-4 mx-1 text-white ${
                currentPage === i + 1 ? "bg-palette" : "bg-paletteof"
              }`}
              onClick={() => setCurrentPage(i + 1)}
              style={{ cursor: "pointer" }}
            >
              {i + 1}
            </div>
          ))}
          {currentPage < totalPages && (
            <button
              className="text-4xl ml-2 -mt-1 md:mt-0 hover:scale-125"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              &gt; {/* Right arrow */}
            </button>
          )}
        </div>
        {/* <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.85)", // Background color with transparency
              zIndex: 10000, // Set z-index to 10000
            },
            content: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              borderColor: "#e7b847",
            },
          }}
        >
          <div className="container mt-20 mb-10">
            <h1 className="sticky z-20 text-white text-2xl border-b-2 border-palette mb-5 -mt-16">
              {selectedImage && selectedImage.title}
            </h1>
            <button
              className="absolute right-5 md:top-2 top-5 mt-2 mr-2  text-white hover:scale-105 bg-palette px-2 sm:text-3xl text-2xl font-bold"
              onClick={closeModal}
            >
              X
            </button>
            <div
              data-aos="fade-up"
              data-aos-duration="2500"
              className="grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto"
            >
              {selectedImage &&
                selectedImage.additional_images.map((img, index) => (
                  <div
                    key={index}
                    className="w-full rounded hover:cursor-pointer hover:scale-105"
                    onClick={() => handleOpenImage(index)}
                    tabIndex={0}
                  >
                    <img
                      src={img}
                      alt="bauspenglerei"
                      className="w-full h-full object-fill object-center"
                    />
                  </div>
                ))}
            </div>
          </div>
          {isOpen && (
            <div className="container sm:fixed absolute inset-0 bg-black bg-opacity-75 h-screen md:h-auto flex items-center justify-center z-10">
              <div className="sm:relative flex justify-center w-fit  border border-black  md:top-0 md:bottom-0">
                <button
                  className="absolute md:-right-16 right-2 md:top-0 top-44 text-white bg-palette px-2 hover:scale-105 md:text-4xl text-2xl font-bold"
                  onClick={handleCloseImage}
                >
                  X
                </button>
                <button
                  className="absolute left-10 md:-left-20 bottom-32 md:bottom-20 md:top-1/2 transform -translate-y-1/2 mt-2  text-white bg-palette px-2 md:bg-transparent rounded-full md:text-7xl text-4xl font-bold"
                  onClick={handlePrevImage}
                >
                  &lt;
                </button>
                <button
                  className="absolute  right-10 md:-right-20  bottom-32 md:bottom-20 md:top-1/2 transform -translate-y-1/2 mt-2   text-white bg-palette px-2 md:bg-transparent rounded-full  md:text-7xl text-4xl font-bold"
                  onClick={handleNextImage}
                >
                  &gt;
                </button>
                <img
                  src={selectedImage.additional_images[currentIndex]}
                  alt="rooms"
                  className="justify-center md:object-cover object-center  h-96  md:h-152"
                />
              </div>
            </div>
          )}
        </Modal> */}
      </div>
    </div>
  );
}

export default Projects;
