import {  useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BA2 from "../../media/Bauspenglerei/BA2.jpeg"
import FL2 from "../../media/Flachdach/FL2.jpeg"
import BL17 from "../../media/Blitzschutze/BL17.jpeg"
function Services() {
  useEffect(() => {
    AOS.init();
  }, []);
  function handleCardClick(path) {
    localStorage.setItem("selectedCardPath", path);
  }
  return (
    <div className=" ">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className=" mt-10 text-3xl text-palette text-center "
      >
        Dienstleistungen
      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 md:ml-24 mr-8 md:mr-24 mb-10 "></div>
      </div>
      <div className="flex justify-center">
      <div className="sm:grid grid-cols-3 ml-auto md:ml-24 mr-auto md:mr-24 mb-10 ">
        <div
          data-aos="fade-right"
          data-aos-duration="2000"
          className="md:relative max-w-md   bg-palette  border-solid shadow-xl sm:ml-4 ml-14 sm:mr-4 mr-14 sm:mb-12 mb-16  sm:hover:scale-105"
          onClick={() => handleCardClick("/bauspengler")}
        >
          <a href="/bauspengler">
            <img
              className=" h-52 md:h-80 w-full object-cover object-center"
              src={BA2}
              alt=""
            />
          </a>
          <div className="p-5">
            <a href="/bauspengler">
              <h5 className="mb-2 text-2xl text-center text-white tracking-tight">
                Bauspenglerei
              </h5>
            </a>
          </div>
          <a href="/bauspengler">
            <div
              class="sm:block hidden  md:absolute md:inset-0 bg-opacity-90  bg-palette   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/bauspengler")}
            >
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
                Bauspenglerei
              </h5>
            </div>
          </a>
        </div>
        {/* flachdach Card */}
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          class="md:relative max-w-md   bg-palette border-solid shadow-xl sm:ml-4 ml-14 sm:mr-4 mr-14  sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/flachdach")}
        >
          <a href="/flachdach">
            <img
              className=" h-52 md:h-80 object-cover object-center w-full"
              src={FL2}
              alt=""
            />
          </a>
          <div class="p-5">
            <a href="/flachdach">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
              Flachdach
              </h5>
            </a>
          </div>
          <a href="/flachdach">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-90  bg-palette   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/flachdach")}
            >
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
              Flachdach
              </h5>
            </div>
          </a>
        </div>
        {/* blitzschutz Card */}
        <div
          data-aos="fade-left"
          data-aos-duration="2000"
          class="md:relative max-w-md   bg-palette border-solid shadow-xl sm:ml-4 ml-14 sm:mr-4 mr-14  sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/blitzschutz")}
        >
          <a href="/blitzschutz">
            <img
              class=" h-52 md:h-80 object-cover object-center w-full"
              src={BL17}
              alt="blitzschutz"
            />
          </a>
          <div class="p-5">
            <a href="/blitzschutz">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
              Blitzschutz
              </h5>
            </a>
          </div>
          <a href="/blitzschutz">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-90  bg-palette   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/blitzschutz")}
            >
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
              Blitzschutz
              </h5>
            </div>
          </a>
        </div>
      </div>
      </div>
    </div>
  );
}
export default Services;
