import FL1 from "./../../media/Flachdach/FL1.jpeg"
import FL2 from "./../../media/Flachdach/FL2.jpeg"
import FL3 from "./../../media/Flachdach/FL3.jpeg"
import FL4 from "./../../media/Flachdach/FL4.jpeg"
import FL5 from "./../../media/Flachdach/FL5.jpeg"
import FL7 from "./../../media/Flachdach/FL7.jpeg"
import FL8 from "./../../media/Flachdach/FL8.jpeg"
import FL9 from "./../../media/Flachdach/FL9.jpeg"
import FL10 from "./../../media/Flachdach/FL10.jpeg"
import FL11 from "./../../media/Flachdach/FL11.jpeg"
import FL12 from "./../../media/Flachdach/FL12.jpeg"
import FL13 from "./../../media/Flachdach/FL13.jpeg"
import FL14 from "./../../media/Flachdach/FL14.jpeg"
import FL15 from "./../../media/Flachdach/FL15.jpeg"
import FL16 from "./../../media/Flachdach/FL16.jpeg"
import FL17 from "./../../media/Flachdach/FL17.jpeg"
import FL18 from "./../../media/Flachdach/FL18.jpeg"
import FL19 from "./../../media/Flachdach/FL19.jpeg"
import FL20 from "./../../media/Flachdach/FL20.jpeg"
import FL21 from "./../../media/Flachdach/FL21.jpeg"
import FL22 from "./../../media/Flachdach/FL22.jpeg"
import FL23 from "./../../media/Flachdach/FL23.jpeg"

const DataF =[
  // {
  //     category: "Flachdach",
  //     image: FL1,
  //     title: "Card 1",
  //     // additional_images: [
  //     //   B2,
  //     //   B3,
  //     //   B4,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B10,
  //     //   B2,
  //     //   B3
  //     // ]
    
  // },
  {
    category: "Flachdach",
    image: FL1,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL2,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL3,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL4,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL5,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL7,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL8,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL9,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL10,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL11,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL12,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL13,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL14,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL15,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL16,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL17,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL18,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL19,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL20,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL21,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL22,
    title: "Flachdach",
  },
  {
    category: "Flachdach",
    image: FL23,
    title: "Flachdach",
  }


]

export default DataF;