import React from "react";
import Navbar from "../Navbar";
import Header from "./HeaderFl";
import Projects from "./Projects";
import Footer from "../Footer";
function Flachdach() {
  return (
    <div className=" ">
      <Navbar />
      <Header />
      <Projects />
      <Footer />
    </div>
  );
}
export default Flachdach;
